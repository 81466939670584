<template>
  <div class="w-full h-full bg-white">
    <da-loader  v-bind:overlay="isLoading"></da-loader>
    <div class="h-full w-full bg-center bg-cover" :style="`background-image: url('`+bgImage+`')`">

        <div class="flex justify-center">
            <div class="mt-5">
              <v-img 
              aspect-ratio="16/9"
              width="350"
              :src="bgLogotype">
              </v-img>
            </div>
        </div>

        <div class="grid justify-items-center ">
            <div class="sm:mt-10 md:mt-5 lg:mt-10 xl:mt-5 ">
              <!-- INICIA FORM LOGIN  -->
              <div :class="classFormAuth">
                <div class="text-center pt-8 pb-5">
                  <h1 class="font-semibold text-primary-700 text-5xl md:text-5xl lg:text-6xl">HOLA.</h1>
                  <h1 class="font-thin text-primary-700 text-5xl md:text-5xl lg:text-6xl">BIENVENIDA</h1>
                </div>
                <div class="w-full flex justify-center pl-4 pr-4">
                  <div class="sm:mt-0 md:mt-2 w-80">
                    
                    <da-text-field-primary 
                      :icon="'mdi-phone'"
                      :label="'Teléfono'"
                      :value="$v.auth.param"
                      :error="$v.auth.param.$invalid && $v.auth.param.$anyDirty && $v.auth.param >= $v.auth.param.$params.maxLength.max" 
                      v-model="$v.auth.param.$model"
                      number
                       @keydown.native="hasError.error = false"
                      @keypress.enter.native="onLogin"
                      :rules="[ ($v.auth.param.$anyDirty ? $v.auth.param.phonePattern : true) || 'Máximo 10 caracteres', !hasError.error || hasError.message ]"
                    />
                    <da-text-field-primary
                      ref="textpwd"
                      class="mt-5" 
                      :icon="'mdi-key'"
                      :label="'Contraseña'"
                      :value="$v.auth.pwd"
                      :error="$v.auth.pwd.$invalid && $v.auth.pwd.$anyDirty" 
                      v-model="$v.auth.pwd.$model"
                      :vtype="showPWD ? 'text': 'password'"
                      @keypress.enter.native="onLogin"
                      :rules="[!hasError.error || hasError.message]"
                    >
                    <template slot="append-icon">
                            <v-icon @click="showPWD = !showPWD">{{!showPWD ? 'mdi-eye' : 'mdi-eye-off'}}</v-icon>
                    </template>
                    </da-text-field-primary>

                    <v-checkbox
                    v-model="$v.auth.refresh.$model"
                    label="Mantener la sesión iniciada"
                    color="gray"
                    hide-details
                    class="mt-0 pt-0"
                  ></v-checkbox>

                    <div class="mt-12 pb-8 text-center">
                      <da-button-primary @click.native="onLogin" :label="'ENTRAR'" r2xl swpr class="w-full py-2"></da-button-primary>
                      <h6 class="text-black text-xs mt-8" @click="validateInputsAuth(1)"><strong class="cursor-pointer">¿Olvidaste tu contraseña?</strong></h6>
                    </div>

                  </div>
                </div>

              </div>
              <!-- FIN FORM LOGIN  -->

              <!-- INICIA SECCION DE OLVIDE CONTRASEÑA -->
              <div :class="classFormPhone">
                <div class="grid justify-items-center mb-10">
                    <h3 class="mt-16 text-primary-900 tracking-wider text-primary">Recuperación de contraseña</h3>
                    <div class="w-64">
                      <v-divider
                        class="mt-7 mb-8"
                        color="#ADADAD"
                      ></v-divider>
                    </div>
                    <div class="w-56 text-center"> <p class="text-normal overflow-ellipsis overflow-hidden text-gray-400 mb-5">Para continuar, favor de ingresar tu número de teléfono</p></div>
                </div>

                <div class="w-full flex justify-center pl-4 pr-4">
                  <div class="sm:mt-0 md:mt-2 w-80">
                    <v-text-field
                      v-model="phoneOtp"
                      label="Teléfono"
                      prepend-inner-icon="mdi-phone"
                      color="#E7AC18"
                      :error="phoneError"
                      @keydown.native="phoneError = false,hasErrorOTP.error = false"
                    ></v-text-field>
                    <h5 v-show="hasErrorOTP.error" class="text-red-600 text-xs">{{hasErrorOTP.message}}</h5>
                    <div class="text-center sm:mt-2 lg:mt-4">
                        <da-button-primary @click.native="validateInputsAuth(3)" :label="'CONTINUAR'" r2xl swpr class="w-full py-2 mt-4 tracking-wider"></da-button-primary>
                        <da-button-secondary @click.native="validateInputsAuth(2)" :label="'CANCELAR'" r2xl swsec class="w-full py-2 mt-4 mb-4 tracking-wider"></da-button-secondary>     
                    </div>

                  </div>
                </div>
                
              </div>

              <div :class="classFormOtp">
                <div class="grid justify-items-center ">
                    <h3 class="mt-10 text-primary-900 tracking-wider text-primary text-center sm:w-72 md:w-80 lg:w-90">Ingresa el código de verificación que fue enviado a tu correo</h3>
                    <div class="w-64">
                      <v-divider
                        class="mt-7 mb-8"
                        color="#ADADAD"
                      ></v-divider>
                    </div>
                    <div class="w-56 text-center"> 
                      <p class="text-normal text-gray-400 mb-0">¿No te llego el código?</p>
                      <button class="text-sm text-gray-500 font-semibold" @click="validateInputsAuth(3)">Reenviar</button>
                      <!-- <p class="text-normal text-gray-400 mb-5">Tiempo para reenviar código: <strong class="text-base"> {{minutes + ":" + seconds}}</strong> </p> -->
                    </div>
                </div>

                <div class="w-full flex justify-center pl-4 pr-4">
                  <div class="sm:mt-0 md:mt-2 w-80">

                    <h1 class="text-center text-lg">Código de verificación</h1>
                    <v-otp-input
                      v-model="otp"
                      :length="length"
                      class="sm:ml-0 md:ml-2 lg:ml-14 sm:mr-0 md:mr-2 lg:mr-14"
                      color="#E7AC18"
                      @keydown.native="hasErrorVOTP.error = false"
                    ></v-otp-input>
                    <h5 v-show="hasErrorVOTP.error" class="text-red-600 text-xs">{{hasErrorVOTP.message}}</h5>
                    <div class="text-center sm:mt-2 lg:mt-4">
                        <da-button-primary @click.native="validateInputsAuth(4)" :label="'CONTINUAR'" r2xl swpr class="w-full py-2 mt-4 tracking-wider"></da-button-primary>
                        <da-button-secondary @click.native="validateInputsAuth(2)" :label="'CANCELAR'" r2xl swsec class="w-full py-2 mt-4 mb-4 tracking-wider"></da-button-secondary>     
                    </div>

                  </div>
                </div>
              </div>

              <div :class="classFormPass">
                <div class="grid justify-items-center mb-1">
                    <h3 class="mt-5 text-primary-900 tracking-wider text-primary">Ingresa tu nueva contraseña</h3>
                    <div class="w-64">
                      <v-divider
                        class="mt-7 mb-8"
                        color="#ADADAD"
                      ></v-divider>
                    </div>
                    <div class="w-56 text-center"> <p class="text-normal overflow-ellipsis overflow-hidden text-gray-400 mb-5">Ingresa tu nueva contraseña, debe de coincidir en ambas casillas</p></div>
                </div>

                <div class="w-full flex justify-center pl-4 pr-4">
                  <div class="sm:mt-0 md:mt-2 w-80">
  
                    <v-text-field
                      class="mt-0 ml-4 mr-4"
                      label="Ingresa contraseña"
                      prepend-inner-icon="mdi-key"
                      color="#E7AC18"
                      id="pwd"
                      type="password"
                      v-model="newPwd"
                      :error="ErrorPwd"
                      @keydown.native="ErrorPwd = false, ErrorPd = false"
                    ></v-text-field>

                    <v-text-field
                      class="mt-2 ml-4 mr-4"
                      label="Ingresa nuevamente"
                      prepend-inner-icon="mdi-key"
                      color="#E7AC18"
                      id="pwd2"
                      type="password"
                      v-model="newPwdRepet"
                      :error="ErrorPwdRepeat"
                      @keydown.native="ErrorPwdRepeat = false, ErrorPd = false"
                    ></v-text-field>
                    <h5 v-show="ErrorPd" class="text-red-600 text-xs">{{ErrorPwdRepeatM}}</h5>
                    <h5 v-show="hasErrorPWD.error" class="text-red-600 text-xs">{{hasErrorPWD.message}}</h5>
                    <div class="text-center sm:mt-2 lg:mt-4">
                        <da-button-primary @click.native="validateInputsAuth(5)" :label="'CONTINUAR'" r2xl swpr class="w-full py-2 mt-4 tracking-wider"></da-button-primary>
                        <da-button-secondary @click.native="validateInputsAuth(2)" :label="'CANCELAR'" r2xl swsec class="w-full py-2 mt-4 mb-4 tracking-wider"></da-button-secondary>     
                    </div>
                  </div>
                </div>
                
              </div>
              <!-- FIN OLVIDE CONTREASEÑA -->
            </div>
        </div>

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moduleTypes from '../../store/modules/common/module-types';
import fnTypes from '../../store/modules/common/fn-types';
import DAAuthModel from '../../models/auth/DAAuthModel';
import { validationMixin } from 'vuelidate';
import { required, requiredIf, helpers, maxLength } from 'vuelidate/lib/validators';

const phonePattern = helpers.regex('numeric', /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/)

export default {
  mixins: [validationMixin],
  data: function() {
    return {
      otp:                '',
      newPwd:             '',
      newPwdRepet:        '',
      ErrorPwdRepeatM:    '',
      phoneOtp:           null,
      length:             5,
      isEnded:            null,
      endDate:            new Date(),
      showPWD:            false,
      classInputPassWord: false,
      classInputPhone:    false,
      isLoading:          false,
      phoneError:         false,
      ErrorPd:            false,
      ErrorPwd:           false,
      ErrorPwdRepeat:     false,
      classFormAuth:      "sm:w-92 md:w-96 lg:w-128 h-128 bg-white rounded-lg shadow-xl animate-fade-in-up sm:mt-0 md:mt-5 lg:mt-10",
      classFormPhone:     "hidden",
      classFormOtp:       "hidden",
      classFormPass:      "hidden",
      auth:               new DAAuthModel(),
      bgImage:            require('@/assets/login/doBackGround.png'),
      bgLogotype:         require('@/assets/login/Logo.svg'),
    }
  },
  validations: {
    auth: {
        param: {
          required,
          phonePattern,
          maxLength: maxLength(10)
        },
        pwd: {
          required: requiredIf( function() {
            return this.auth.param != "" ? true : false
          })
        },
        refresh: {
          required
        }
      }
    },
  computed: {
    ...mapGetters(moduleTypes.AUTH, {
      isAuthorized:   fnTypes.isAuthorized,
      isOtpSend:      fnTypes.sendOtp,
      isValidateOtp:  fnTypes.validateOtp,
      isPwdUpdated:   fnTypes.updatePwd,
      hasError:       fnTypes.hasError,
      hasErrorOTP:    fnTypes.hasErrorOTP,
      hasErrorVOTP:   fnTypes.hasErrorVOTP,
      hasErrorPWD:    fnTypes.hasErrorPWD      
    }),
    isActive () {
        return this.otp.length === this.length
    },
  },
  methods: {
    ...mapActions(moduleTypes.AUTH, [
      fnTypes.login, fnTypes.sendOtp, fnTypes.validateOtp, fnTypes.updatePwd
    ]),

    validateInputsAuth: async function(opcion){
            if(opcion == 1){
                this.classFormAuth  = 'hidden'
                this.classFormOtp   = 'hidden'
                this.classFormPass  = 'hidden'
                this.classFormPhone = 'sm:w-92 md:w-96 lg:w-128 h-128 bg-white rounded-lg shadow-xl animate-fade-in-right  sm:mt-0 md:mt-5 lg:mt-10'
            }
            else if(opcion == 2){

               this.phoneOtp = null
               this.phoneError = false
                this.classFormPhone = 'hidden'
                this.classFormOtp   = 'hidden'
                this.classFormPass  = 'hidden'
                this.classFormAuth  = 'sm:w-92 md:w-96 lg:w-128 h-128 bg-white rounded-lg shadow-xl animate-fade-in-right  sm:mt-0 md:mt-5 lg:mt-10'
            }
            else if(opcion == 3){
              if(this.phoneOtp == '' || this.phoneOtp == undefined || this.phoneOtp == null){
                this.phoneError = true
                  return
              }
              this.OnsendOtp()
            }
            else if(opcion == 4){
              if(this.otp == '' || this.otp == undefined || this.otp == null){
                this.phoneError = true
                  return
              }
              this.OnValidateOtp()
            }
            else if(opcion == 5){

              if(this.newPwd == '' || this.newPwd == undefined || this.newPwd == null){
                this.ErrorPwd = true
                  return
              }
              if(this.newPwdRepet == '' || this.newPwdRepet == undefined || this.newPwdRepet == null){
                this.ErrorPwdRepeat = true
                  return
              }
              if(this.newPwd != this.newPwdRepet ){
                this.ErrorPd = true
                this.ErrorPwdRepeatM = 'Las contraseñas no coinciden'
                  return
              }

              if(this.newPwd.length < 5){
                this.ErrorPd = true
                this.ErrorPwdRepeatM = 'La contraseña debe contener mínimo 5 caracteres'
                  return
              }
              this.onChangePassword()
            }

    },
    
    onLogin: async function() {
      this.$v.$touch();
      if(this.auth.param == '' || this.auth.param == undefined){
          this.classInputPhone = true
          return
      }
      if(this.auth.pwd == '' || this.auth.pwd == undefined){
          this.classInputPassWord = true
          return
      }

      this.isLoading = true
      if(!this.isAuthorized) {
        await this.login(this.auth)
        if(this.isAuthorized) {
          this.isLoading = false
          this.$router.push('/dashboard')
        }
        this.isLoading = false
      }
    },

    OnsendOtp: async function() {
      var t = {'phone': this.phoneOtp}
      this.isLoading = true
      await this.sendOtp(t)
      if(this.isOtpSend) {
          this.classFormAuth  = 'hidden'
          this.classFormPhone = 'hidden'
          this.classFormPass  = 'hidden'
          this.classFormOtp   = 'sm:w-92 md:w-96 lg:w-128 h-128 bg-white rounded-lg shadow-xl animate-fade-in-right  sm:mt-0 md:mt-5 lg:mt-10'
        }
        this.isLoading = false
    },

    OnValidateOtp: async function() {
      var t = {'telephone': this.phoneOtp,'code': this.otp}
      this.isLoading = true
      await this.validateOtp(t)
      if(this.isValidateOtp) {
          this.classFormAuth  = 'hidden'
          this.classFormPhone = 'hidden'
          this.classFormOtp   = 'hidden'
          this.classFormPass  = 'sm:w-92 md:w-96 lg:w-128 h-128 bg-white rounded-lg shadow-xl animate-fade-in-right  sm:mt-0 md:mt-5 lg:mt-10'
      }
      this.isLoading = false
    },

    onChangePassword: async function() {
      var t = {'phone': this.phoneOtp,'otp': this.otp, 'password':this.newPwd}
      this.isLoading = true
      await this.updatePwd(t)
      if(this.isValidateOtp) {
        this.otp          = null
        this.phoneOtp     = null
        this.newPwdRepet  = null
        this.newPwd       = null
        this.validateInputsAuth(2)
      }
      else{
        this.ErrorPd = true
        this.ErrorPwdRepeatM = 'Ocurrio un error inesperado al actualizar la información, Por favor, Intenta más tarde.'
      }
      this.isLoading = false
    }

  }
}
</script>

<style>

</style>